import React, { Component } from 'react';

import { getData, BreadTreeElement } from '../../../utils/PageApi';
import { PageBaseLayout, PageDescription } from '../..';
import { buttonText } from '../../../constants/buttonText';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import NormalPrice from './NormalPrice';

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    priceList1Title: string;
    priceList1Description: string;
    priceList1TableShortCode: string;
  };
  slug: string;
  parent: number;
};

interface OwnProps {}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

class Price extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          priceList1Title: '',
          priceList1Description: '',
          priceList1TableShortCode: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
    };
  }

  async createData() {
    let pageId = 878;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
  }

  componentDidMount() {
    this.createData();
  }

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={'#fff'}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <PageDescription>
          <div dangerouslySetInnerHTML={{ __html: dataAcf.pageDescription }} />
        </PageDescription>
        <NormalPrice
          title={dataAcf.priceList1Title}
          description={dataAcf.priceList1Description}
          table={dataAcf.priceList1TableShortCode}
        />
      </PageBaseLayout>
    );
  }
}
export default Price;
