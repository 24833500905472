export default {
  primary: '#E8282B',
  secondary: '#efede0',
  blue: '#2699FB',
  lightGray: '#F0F0F0',
  gray: '#ccc',
  darkGray: '#999999',
  buttonLightBlue: '#3EAADD',
  buttonDarkBlue: '#3798C4',
  footerBackGroudn: '#666666',
  immigrationBlue: '#282EE8',
  red: '#E8282B',
  bulletsGray: '#666',
  pink: '#fd84ac',
  green: '#6cbc67',
};
