import React, { Component } from 'react';
import styled from 'styled-components';
import fonts from '../../fonts';

import { LinkHandle } from '../../';

const MenuBox = styled.div`
  width: 100%;
  padding-right: 2rem;
  &:last-child {
    padding-right: 0rem;
  }
  .items {
    font-weight: bold;
  }
  .item li {
    font-weight: 100;
  }
  &:last-child {
    max-width: 200px;
  }
`;
const MenuBoxUl = styled.ul`
  font-family:${fonts.jpBold}
  font-size:${({ theme }) => theme.fontSize}
  margin-left: 0;
  & a {
    display: block;
    padding: 0.6rem 0;
  }
  & li {
    margin-bottom: 0.4rem;
  }
`;

interface OwnProps {
  class: string;
  menu: {
    title: string;
    url: string;
    children: [{ title: string; url: string }];
  };
}
interface OwnState {}

class FooterProgram extends Component<OwnProps, OwnState> {
  render() {
    return (
      <MenuBox className={this.props.class}>
        <MenuBoxUl
          theme={{
            fontSize: '1.4rem',
          }}
          className="menuItems"
        >
          <li className="items">
            <LinkHandle to={this.props.menu.url}>
              {this.props.menu.title}
            </LinkHandle>
            <MenuBoxUl
              theme={{
                fontSize: '1.2rem',
              }}
              className="item"
            >
              {this.props.menu.children &&
                this.props.menu.children.map(subitem => (
                  <li key={subitem.title}>
                    <LinkHandle to={subitem.url}>{subitem.title}</LinkHandle>
                  </li>
                ))}
            </MenuBoxUl>
          </li>
        </MenuBoxUl>
      </MenuBox>
    );
  }
}
export default FooterProgram;
