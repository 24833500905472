import React, { Component } from 'react';
import styled from 'styled-components';

import { Button, LinkHandle } from '../..';
import fonts from '../../fonts';
const BoxDiv = styled.div`
  width: 80%;
  align-content: center;
  margin: 0 auto;
  padding-bottom: 32px;
  margin-bottom: 32px;
`;

const ButtonDiv = styled.div`
  text-align: center;
`;
const TitleDiv = styled.div`
  font-family: ${fonts.jpMedium};
  font-size: 2.4rem;
  font-weight: bold;
`;
const TextDiv = styled.div`
  margin: 24px auto;

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: normal;
    margin-top: 2.85rem;
    margin-bottom: 1.45rem;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 2.85rem;
    margin-bottom: 1.45rem;
  }

  a {
    color: rgb(38, 153, 251);
  }

  a:hover {
    color: rgb(232, 40, 43);
  }
`;

interface OwnProps {
  title: string;
  excerpt: string;
  link: string;
}
interface OwnState {}

class NewsContent extends Component<OwnProps, OwnState> {
  render() {
    return (
      <BoxDiv>
        <TitleDiv>{this.props.title}</TitleDiv>
        <TextDiv dangerouslySetInnerHTML={{ __html: this.props.excerpt }} />
        <ButtonDiv>
          <Button theme={{ main: '23.7rem' }}>
            <LinkHandle to={this.props.link}>お問い合わせ</LinkHandle>
          </Button>
        </ButtonDiv>
      </BoxDiv>
    );
  }
}
export default NewsContent;
