import React, { Component } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';

import { HashLinkHandle } from '../../../utils/HashLinkHandle';
import { PageBaseLayout, Heading2 } from '../..';
import { getData, BreadTreeElement } from '../../../utils/PageApi';
import { buttonText } from '../../../constants/buttonText';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import EditorContent from '../../Common/EditorContent';
import color from '../../colors';

const MainSection = styled.section`
  padding: 8rem 0;
`;

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
  };
  slug: string;
  parent: number;
};

interface OwnProps extends RouteComponentProps {
  pageID: number;
}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

class HelpfulInfoChildPageBase extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
    };
  }

  async createData() {
    let pageId = this.props.pageID;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
    HashLinkHandle(this.props);
  }

  componentDidMount() {
    this.createData();
  }

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={color.lightGray}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <MainSection>
          <Heading2>{title}</Heading2>
          <EditorContent>
            <div
              dangerouslySetInnerHTML={{ __html: dataAcf.pageDescription }}
            />
          </EditorContent>
        </MainSection>
      </PageBaseLayout>
    );
  }
}
export default withRouter(HelpfulInfoChildPageBase);
