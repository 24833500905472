import React, { Component } from 'react';

import HelpfulInfoChildPageBase from '../HelpfulInfoChildPageBase';

interface OwnProps {}
interface OwnState {}

class WhVisaApply2 extends Component<OwnProps, OwnState> {
  render() {
    return <HelpfulInfoChildPageBase pageID={2061} />;
  }
}
export default WhVisaApply2;
