import React, { Component } from 'react';

import { LinkHandle } from '../../';
import MobileSubMenu from './MobileSubMenu';
import { getHeader } from '../../../utils/MenuApi';
import styled from 'styled-components';

const Li = styled.li`
  padding-bottom: 1.5rem;
`;
const MobileUl = styled.ul`
  a {
    color: rgb(189, 195, 199);
  }
`;

interface OwnProps {
  handleMenuClick: any;
}
interface OwnState {
  menus: {
    items: [
      {
        title: string;
        url: string;
        children: [{ title: string; url: string }];
      },
    ];
  };
}

class MobileMenu extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      menus: {
        items: [{ title: '', url: '', children: [{ title: '', url: '' }] }],
      },
    };
  }
  async getMenuData() {
    const headerData = await getHeader();
    await this.setState({
      menus: headerData,
    });
  }
  componentDidMount() {
    this.getMenuData();
  }

  handleMenuClick = () => {
    return this.props.handleMenuClick();
  };
  render() {
    let menus = this.state.menus.items;
    return (
      <MobileUl id="">
        {menus.map((item, index) => (
          <Li key={index} className="menus" onClick={this.handleMenuClick}>
            <LinkHandle to={item.url}>{item.title}</LinkHandle>
            {item.children && <MobileSubMenu subMenus={item.children} />}
          </Li>
        ))}
      </MobileUl>
    );
  }
}
export default MobileMenu;
