import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { IP } from '../../utils/IPadress';

type MetaData = {
  title: string;
  metaDescription: string;
  eyeCatchId: number;
  ogLink: string;
};

interface OwnProps {
  metaData: MetaData;
}
interface OwnState {
  guid: {
    rendered: string;
  };
}

const defaultEveCatch = `${IP}/wp-content/uploads/2019/09/eyecatch_defalt.jpg`;
const SITE_NAME = 'COSカナダ留学サポートデスク';

class Meta extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      guid: {
        rendered: '',
      },
    };
  }

  // 各ページのアイキャッチ画像を取得
  // async getEyeCatchData(eyeCatchId: number) {
  //   const eyeCatchData = await getEyeCatch(eyeCatchId);
  //   await this.setState({ guid: eyeCatchData });
  // }

  // getEyeCatchImg(eyeCatchId: number) {
  //   this.getEyeCatchData(eyeCatchId);
  //   return this.state.guid.rendered;
  // }

  render() {
    const { metaData } = this.props;
    return (
      <Helmet>
        <title>{`${SITE_NAME}｜${metaData.title}`}</title>
        <meta name="description" content={metaData.metaDescription} />
        <meta
          property="og:title"
          content={`${SITE_NAME}｜${metaData.title}`}
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={defaultEveCatch}></meta>
        <meta property="og:image:secure_url" content={defaultEveCatch}></meta>
        <meta property="og:site_name" content={SITE_NAME}></meta>
        <meta
          property="og:description"
          content={metaData.metaDescription}
        ></meta>
        <meta property="og:url" content={metaData.ogLink}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:title"
          content={`${SITE_NAME}｜${metaData.title}`}
        ></meta>
        <meta
          name="twitter:description"
          content={metaData.metaDescription}
        ></meta>
        <meta name="twitter:image" content={defaultEveCatch}></meta>
        <meta name="twitter:site" content={metaData.ogLink}></meta>
        <link rel="canonical" href={metaData.ogLink}></link>
      </Helmet>
    );
  }
}
export default Meta;
