import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '../..';
import { media } from '../../../utils/Helper';
import colors from '../../colors';
import ServiceBox from './ServiceBox';
import type {
  PageTemplateCustomFields,
  WebDevelopmentCustomFields,
} from './types';

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  ${media.tablet`
  flex-direction: column;
  align-items: center;
  `}
`;

const ServiceDiv = styled.div`
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-left: calc(((100vw - 100%) / 2) * -1);
  padding: 84px 7.5% 0;
  background-color: ${colors.lightGray};
`;

const BoxDiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    font-size: 3rem;
  }
`;

type OwnProps = {
  data: PageTemplateCustomFields & WebDevelopmentCustomFields;
}

const ServiceSection = (props: OwnProps) => {
  const { data } = props;
  const {
    serviceTitle,
    serviceBox1title, serviceBox1,
    serviceBox2title, serviceBox2,
    serviceBox3title, serviceBox3,
  } = data;

  return (
    <ServiceDiv>
      <BoxDiv>
        <Heading2>{serviceTitle}</Heading2>
        <FlexDiv>
          <ServiceBox key={0} title={serviceBox1title} content={serviceBox1} />
          <ServiceBox key={1} title={serviceBox2title} content={serviceBox2} />
          <ServiceBox key={2} title={serviceBox3title} content={serviceBox3} />
        </FlexDiv>
      </BoxDiv>
    </ServiceDiv>
  );
};

export default ServiceSection;
