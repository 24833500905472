import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '../..';
import { media } from '../../../utils/Helper';
import {
  PageTemplateCustomFields,
  WebDevelopmentCustomFields,
} from './types';

const CompetitivePricingDiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 84px 0 42px;

  .info.web-development .title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .info.web-development {
    ${media.tablet`
    padding: 6.7rem 2rem;
    `}
  }
`;

const Description = styled.div`
  text-align: center;
`;

type OwnProps = {
  data: PageTemplateCustomFields & WebDevelopmentCustomFields;
}

const CompetitivePricingSection = (props: OwnProps) => {
  const { data } = props;
  const {
    competitivePricingTitle,
    competitivePricingDescription = '',
  } = data;

  return (
    <CompetitivePricingDiv>
      <Heading2>{competitivePricingTitle}</Heading2>
      <Description
        dangerouslySetInnerHTML={{ __html: competitivePricingDescription }}
      />
    </CompetitivePricingDiv>
  );
};

export default CompetitivePricingSection;
