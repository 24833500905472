import { IP } from './IPadress';
export function getHeader() {
  return getMenuApi(15);
}
export function getFooterSubMenu() {
  // WordPress管理画面 menu名：FooterSubMenu
  return getMenuApi(14);
}
export function getFooterMainMenu() {
  // WordPress管理画面 menu名：FooterMainMenu
  return getMenuApi(13);
}
async function getMenuApi(menuId: number) {
  let dataURL = `${IP}/wp-json/wp-api-menus/v2/menus/${menuId}`;
  const responseData = await fetch(dataURL);
  const data = await responseData.json();
  return data;
}
