import React from 'react';
import styled from 'styled-components';
import {
  Heading2,
  Heading3,
} from '../../..';
import { media } from '../../../../utils/Helper';

const TableBox = styled.div`
  h3 {
    width: 65.5%;
    ${media.tablet`width: 100%;`};
    margin: 0 auto 25px;
  }

  .tablepress {
    width: 65.5%;
    ${media.tablet`width: 100%;`};
    margin: 0 auto;
  }
`;

const BoxDiv = styled.div`
  padding: 4rem 0 8rem 0;
  background-color: ${({ theme }) => theme.backgroundColor};

  &.backGray {
    margin-right: calc(((100vw - 100%) / 2) * -1);
    margin-left: calc(((100vw - 100%) / 2) * -1);
    padding: 8rem 8.3rem;
    ${media.tablet`padding: 8rem 1.6rem;`}
  }
`;

interface OwnProps {
  vancouverBranchTable: string;
  osakaBranchTable: string;
}

const BranchTables = (props: OwnProps) => {
  const { vancouverBranchTable } = props;

  return (
    <TableBox>
      <BoxDiv theme={{ backgroundColor: '#fff' }}>
        <Heading2>カナダ</Heading2>
        <Heading3>バンクーバー オフィス(本社)</Heading3>
        <div
          className="vancouver"
          dangerouslySetInnerHTML={{
            __html: vancouverBranchTable,
          }}
        />
      </BoxDiv>
    </TableBox>
  );
};

export default BranchTables;
