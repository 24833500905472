import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../../utils/Helper';

interface OwnProps {}
interface OwnState {}

const Div = styled.div`
  margin-bottom: 8rem;
  padding: 0 15.2rem;
  padding-top: 4rem;
  text-align: center;
  ${media.tablet`padding:0; margin:2.4rem 0;`}
`;

class PageDescription extends Component<OwnProps, OwnState> {
  render() {
    return <Div>{this.props.children}</Div>;
  }
}
export default PageDescription;
