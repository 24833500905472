import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../../../../utils/Helper';
import fonts from '../../../fonts';

const Div = styled.div`
  &.big {
    width: 50%;
    ${media.tablet`width:100%; padding:0;Padding-bottom:32px;`}
  }
  &.small {
    width: 32%;
    ${media.tablet`width:50%;`}
    &:nth-child(odd) {
      ${media.tablet`margin:0;padding-bottom:32px;padding-right: 0.8rem;`}
    }
    &:nth-child(even) {
      ${media.tablet`margin:0;padding-bottom:32px;padding-left: 0.8rem;`}
    }
  }
  padding: 0 15px 32px 15px;
  & img {
    width: 100%;
    height: auto;
  }
  &:first-child {
    padding-left: 0;
  }
  &:nth-of-type(2) {
    padding-right: 0;
  }
  &:nth-of-type(4),
  :nth-of-type(7),
  :nth-of-type(10),
  :nth-of-type(13) {
    padding: 0 0 32px;
    margin: 0 2%;
  }
  &:nth-of-type(3),
  :nth-of-type(5),
  :nth-of-type(6),
  :nth-of-type(8),
  :nth-of-type(9),
  :nth-of-type(11),
  :nth-of-type(12),
  :nth-of-type(14) {
    padding: 0;
  }
`;

const NameDiv = styled.div`
font-family:${fonts.jpMedium}
font-weight: bold;
font-size:1.6rem;
`;

interface ownProps {
  name: string;
  positionName: string;
  img: string;
  width: string;
  height: string;
  class: string;
}
interface ownState {}
class StaffBox extends Component<ownProps, ownState> {
  render() {
    return (
      <Div className={this.props.class}>
        <div>
          <img
            src={this.props.img}
            width={this.props.width}
            height={this.props.height}
            alt={this.props.name}
          />
        </div>
        <NameDiv>{this.props.name}</NameDiv>
        <div>{this.props.positionName}</div>
      </Div>
    );
  }
}
export default StaffBox;
