import axios from 'axios';
import classNames from 'classnames';
import React, {
  Component,
  Fragment,
} from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/Helper';

import { IP } from '../../../utils/IPadress';
import { ContactSendButton } from './ContactStyle';

const MessageDiv = styled.div`
  font-weight: bold;
  text-align: center;
  margin-bottom: 3.2rem;
  &.error {
    color: red;
  }
`;
const LoadingIconDiv = styled.div`
  text-align: center;
`;

const NoteDiv = styled.div`
  margin: auto;
  padding-bottom: 3.2rem;
  width: 50%;
  ${media.tablet`width:100%;`}
`;

type contactFormData = {
  message: '';
  status: '';
  invalidFields: [{}];
};

interface OwnProps {
  isEnglishVar?: boolean;
}

interface OwnState {
  data: contactFormData;
  isClicked: boolean;
  errorIds: string[];
}

class SendButton extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        message: '',
        status: '',
        invalidFields: [{}],
      },
      isClicked: false,
      errorIds: [''],
    };
  }

  initializeError = () => {
    for (let i = 0; i < this.state.errorIds.length; i++) {
      const errorParent = document.getElementById(
        `errorP-${this.state.errorIds[i]}`,
      ) as HTMLElement;
      const errorChild = document.getElementById(
        `errorC-${this.state.errorIds[i]}`,
      ) as HTMLElement;
      if (errorChild) {
        errorParent.removeChild(errorChild);
      }
    }
    this.setState({ errorIds: [''] });
  };

  formSend = () => {
    this.initializeError();
    this.setState({ isClicked: true });
    let IdFormData: HTMLFormElement;
    IdFormData = document.getElementById('form') as HTMLFormElement;
    document.getElementsByClassName('wpcf7-form');
    const formData = new FormData(IdFormData);
    var formId = (document.getElementsByName('_wpcf7')[0] as HTMLInputElement)
      .value;

    axios
      .post(
        `${IP}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback/`,
        formData,
      )
      .then(res => {
        const resModifyData = {
          message: res.data.message,
          status: res.data.status,
          invalidFields: res.data.invalidFields,
        };
        if (res.data.invalidFields) {
          this.createErrorDom(res.data.invalidFields);
        }
        this.setState({ data: resModifyData, isClicked: false });
      })
      .catch(error => {
        console.log(error);
      });
  };

  createErrorDom = (errorItemArray: any) => {
    let errorClassNames = [];
    for (let i = 0; i < errorItemArray.length; i++) {
      const errorClassName = errorItemArray[i].into.replace(
        'span.wpcf7-form-control-wrap.',
        '',
      );
      const ErrorTargetTag = document.getElementsByClassName(
        errorClassName,
      )[0] as HTMLInputElement;
      const ErrorParentId = `errorP-${errorClassName}`;
      const ErrorChildId = `errorC-${errorClassName}`;
      errorClassNames.push(`${errorClassName}`);

      const errorFamily = `<span id="${ErrorParentId}" class="error-content">\
      <span id="${ErrorChildId}">${errorItemArray[i].message}</span></span>`;

      let errorParentTag = document.getElementById(
        `${ErrorParentId}`,
      ) as HTMLInputElement;

      //<span id="ErrorChildID">${errorItemArray[i].message}</span>
      const errorChildTag = document.createElement(`span`);
      errorChildTag.id = ErrorChildId;
      errorChildTag.innerHTML = errorItemArray[i].message;

      // javascriptで子要素しか削除できない為、初期化するときに親要素が残ってしまう。
      // 複数”送信ボタン”が押下された際に親要素のドムが増えてしまうのを防ぐ為に以下の処理を行う。
      if (errorParentTag) {
        // すでにエラー表示がある場合は、すでに存在する親ドムに子供のみを追加
        errorParentTag.appendChild(errorChildTag);
      } else {
        // すでにエラー表示がない場合は、対象となる箇所(ErrorTargetTag)に
        // 親子を追加
        ErrorTargetTag.insertAdjacentHTML('beforeend', errorFamily);
      }
    }
    this.setState({ errorIds: errorClassNames });
  };

  showLoadingIcon = (isClicked: boolean) => {
    if (isClicked) {
      return (
        <LoadingIconDiv>
          <img
            alt="loading"
            src={`${IP}/wp-content/plugins/contact-form-7/images/ajax-loader.gif`}
          />
        </LoadingIconDiv>
      );
    } else {
      return <div />;
    }
  };

  japaneseNoteDom = (
    <NoteDiv>
      COS留学サポートデスクでは、48時間内のメール対応を心がけています。
      <br />
      <strong>
        48時間内に返信が届かない場合は、メールアドレスのスペルミス、メールサーバのエラー等が考えられますので、大変お手数ですが再度お問い合わせ下さい。
      </strong>
      <br />
      ※日本時間の土、日曜日にご連絡いただきました場合には、時差の関係上、
      返信が日本時間の火曜日以降となることがございますので、あらかじめご了承ください。
    </NoteDiv>
  );

  englishNoteDom = (
    <NoteDiv>
      COS Educational Consulting Inc. strives to respond to emails within 48 hours.
      <br />
      <strong>
        If you do not receive any reply within 48 hours, please contact us again.
        There may be an error on the mail server or a spelling error in your email address.
      </strong>
    </NoteDiv>
  );

  render() {
    const data = this.state.data;
    let status: string = data.status;
    let isSendError: boolean = true;
    const { isEnglishVar } = this.props;
    // "mail_sent" はcontact form7が設定している値
    if (status !== 'mail_sent') {
      isSendError = false;
    }
    return (
      <Fragment>
        <ContactSendButton
          id="buttonSend"
          onClick={this.formSend}
          className={classNames({
            desable: this.state.isClicked,
          })}
        >
          {this.props.isEnglishVar ? 'SEND' : '送信'}
        </ContactSendButton>
        {this.showLoadingIcon(this.state.isClicked)}
        {!this.state.isClicked && (
          <MessageDiv className={classNames({ error: !isSendError })}>
            {data.message}
          </MessageDiv>
        )}
        {isEnglishVar ? this.englishNoteDom : this.japaneseNoteDom}
      </Fragment>
    );
  }
}

export default SendButton;
