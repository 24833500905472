import React, { Component } from 'react';
import styled from 'styled-components';
import { NewsData } from '../../../type/newsData';
import { formatDate } from '../../../utils/dateFormatter';
import LinkHandle from '../../../utils/LinkHandle';
import fonts from '../../fonts';

const BoxDiv = styled.div`
  width: 80%;
  align-content: center;
  margin: 0 auto;
  padding-bottom: 32px;
  border-bottom: 1px solid gray;
  margin-bottom: 32px;
`;

const FlexDiv = styled.div`
  display: flex;
`;

const TitleDiv = styled.div`
  font-family: ${fonts.jpMedium};
  font-size: 1.6rem;
`;

const TextDiv = styled.div`
  font-weight: bold;
  padding-left: 5.8rem;
  p {
    margin-bottom: 0;
  }
`;

interface OwnProps {
  news: NewsData;
}

class NewsListContent extends Component<OwnProps> {
  render() {
    const { news } = this.props;

    return (
      <BoxDiv>
        <LinkHandle to={`/news-list/${news.id}`}>
          <FlexDiv>
            <TitleDiv>{formatDate(news.date)}</TitleDiv>
            <TextDiv dangerouslySetInnerHTML={{ __html: news.excerpt.rendered }} />
          </FlexDiv>
        </LinkHandle>
      </BoxDiv>
    );
  }
}

export default NewsListContent;
