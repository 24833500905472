import React, { Component } from 'react';

import { PageBaseLayout } from '../../..';
import { getData, BreadTreeElement } from '../../../../utils/PageApi';
import { buttonText } from '../../../../constants/buttonText';
import { BottomSectionText } from '../../../../constants/BottomSectionText';
import BranchTables from './BranchTables';

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    vancouverBranchTable: string;
    osakaBranchTable: string;
  };
  slug: string;
  parent: number;
};

interface OwnProps {}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

class Company extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        featured_media: 0,
        link: '',
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          vancouverBranchTable: '',
          osakaBranchTable: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
    };
  }

  async createData() {
    let pageId = 965;
    const state = await getData<PageDataType>(pageId);
    this.setState(state);
  }
  componentDidMount() {
    this.createData();
  }

  render() {
    let data = this.state.data;
    let dataAcf = this.state.data.acf;

    let title = this.state.data.title.rendered;

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={'#fff'}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <BranchTables
          vancouverBranchTable={dataAcf.vancouverBranchTable}
          osakaBranchTable={dataAcf.osakaBranchTable}
        />
      </PageBaseLayout>
    );
  }
}
export default Company;
