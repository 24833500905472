import React, { Component } from 'react';

import { PageBaseLayout, PageDescription } from '../..';
import { buttonText } from '../../../constants/buttonText';
import { BottomSectionText } from '../../../constants/BottomSectionText';
import color from '../../colors';
import AboutCOSContents from './AboutCOSContents';
import { getData, BreadTreeElement } from '../../../utils/PageApi';

type PageDataType = {
  title: { rendered: string };
  link: string;
  featured_media: number;
  acf: {
    metaDescription: string;
    heroImg: string;
    subtitle: string;
    pageDescription: string;
    title1: string;
    description: string;
    img: string;
    title2: string;
    boxTitle1: string;
    boxSubtitle1: string;
    BoxColor1: string;
    boxImg1: string;
    boxLink1: string;
    boxTitle2: string;
    boxSubtitle2: string;
    BoxColor2: string;
    boxImg2: string;
    boxLink2: string;
    boxTitle3: string;
    boxSubtitle3: string;
    BoxColor3: string;
    boxImg3: string;
    boxLink3: string;
  };
  slug: string;
  parent: number;
};

interface OwnProps {}
interface OwnState {
  data: PageDataType;
  breadTreeElements: BreadTreeElement[];
}

class About extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        title: { rendered: '' },
        link: '',
        featured_media: 0,
        acf: {
          metaDescription: '',
          heroImg: '',
          subtitle: '',
          pageDescription: '',
          title1: '',
          description: '',
          img: '',
          title2: '',
          boxTitle1: '',
          boxSubtitle1: '',
          BoxColor1: '',
          boxImg1: '',
          boxLink1: '',
          boxTitle2: '',
          boxSubtitle2: '',
          BoxColor2: '',
          boxImg2: '',
          boxLink2: '',
          boxTitle3: '',
          boxSubtitle3: '',
          BoxColor3: '',
          boxImg3: '',
          boxLink3: '',
        },
        slug: '',
        parent: 0,
      },
      breadTreeElements: [],
    };
  }

  componentDidMount() {
    getData<PageDataType>(1026).then(state => {
      this.setState(state);
    });
  }

  render() {
    let data = this.state.data;
    let dataAcf = data.acf;
    let title = data.title.rendered;

    return (
      <PageBaseLayout
        metaDescription={dataAcf.metaDescription}
        BreadTreeElements={this.state.breadTreeElements}
        heroImgURL={dataAcf.heroImg}
        title={title}
        subTitle={dataAcf.subtitle}
        eyeCatchId={data.featured_media}
        ogLink={data.link}
        bottomSectionText={BottomSectionText.pattern1}
        bottomSectionColor={color.lightGray}
        bottomButtonText={buttonText.freeConsulting}
        bottomButtonSize={'23.7rem'}
      >
        <PageDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: dataAcf.pageDescription,
            }}
          />
        </PageDescription>
        <AboutCOSContents
          img={dataAcf.img}
          title1={dataAcf.title1}
          text={dataAcf.description}
          title2={dataAcf.title2}
          box1Data={{
            title: dataAcf.boxTitle1,
            subTitle: dataAcf.boxSubtitle1,
            backgroundColor: dataAcf.BoxColor1,
            img: dataAcf.boxImg1,
            link: dataAcf.boxLink1,
          }}
          box2Data={{
            title: dataAcf.boxTitle2,
            subTitle: dataAcf.boxSubtitle2,
            backgroundColor: dataAcf.BoxColor2,
            img: dataAcf.boxImg2,
            link: dataAcf.boxLink2,
          }}
          box3Data={{
            title: dataAcf.boxTitle3,
            subTitle: dataAcf.boxSubtitle3,
            backgroundColor: dataAcf.BoxColor3,
            img: dataAcf.boxImg3,
            link: dataAcf.boxLink3,
          }}
        />
      </PageBaseLayout>
    );
  }
}
export default About;
