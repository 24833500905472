import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Heading2,
  ImagePluTextBox,
  LinkHandle,
} from '../..';
import colors from '../../colors';
import type {
  PageTemplateCustomFields,
  WebDevelopmentCustomFields,
} from './types';

const ServiceDiv = styled.div`
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-left: calc(((100vw - 100%) / 2) * -1);
  padding: 84px 7.5%;
  background-color: ${colors.lightGray};
`;

const BoxDiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ButtonDiv = styled.div`
  width: 50%;
  margin: 0 auto;

  button {
    margin-top: 0;
  }
`;

type OwnProps = {
  data: PageTemplateCustomFields & WebDevelopmentCustomFields;
}

const HiringSection = (props: OwnProps) => {
  const { data } = props;
  const {
    hiringTitle = '',
    hiringImg = '',
    hiringText = '',
    hiringButtonLink = '',
  } = data;

  return (
    <ServiceDiv>
      <BoxDiv>
        <Heading2>{hiringTitle}</Heading2>
        <ImagePluTextBox
          maxWidth={'500px'}
          imgURL={hiringImg}
          alt={hiringTitle}
          isImgRightSide={true}
          classname=""
        >
          <div dangerouslySetInnerHTML={{ __html: hiringText }} />
        </ImagePluTextBox>
      </BoxDiv>
      <ButtonDiv>
        <Button>
          {' '}
          <LinkHandle to={hiringButtonLink}>Contact Form</LinkHandle>
        </Button>
      </ButtonDiv>
    </ServiceDiv>
  );
};

export default HiringSection;
