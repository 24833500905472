import React, { Component } from 'react';

import HelpfulInfoChildPageBase from '../HelpfulInfoChildPageBase';

interface OwnProps {}
interface OwnState {}

class Biometrics extends Component<OwnProps, OwnState> {
  render() {
    return <HelpfulInfoChildPageBase pageID={1321} />;
  }
}
export default Biometrics;
