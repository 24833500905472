import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '../..';
import { media } from '../../../utils/Helper';
import FlowStepBox from './FlowStepBox';
import type {
  PageTemplateCustomFields,
  WebDevelopmentCustomFields,
} from './types';

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  ${media.tablet`
  flex-direction: column;
  align-items: center;
  `}
`;

const ProgramDiv = styled.div`
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-left: calc(((100vw - 100%) / 2) * -1);
  padding: 0 7.5% 80px;
`;

const BoxDiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

type OwnProps = {
  data: PageTemplateCustomFields & WebDevelopmentCustomFields;
}

const FlowSection = (props: OwnProps) => {
  const { data } = props;
  const {
    flowTitle,
    flow1Title, flow1Text, flow1Img,
    flow2Title, flow2Text, flow2Img,
    flow3Title, flow3Text, flow3Img,
    flow4Title, flow4Text, flow4Img,
  } = data;

  return (
    <ProgramDiv>
      <BoxDiv>
        <Heading2>{flowTitle}</Heading2>
        <FlexDiv>
          <FlowStepBox key={0} title={flow1Title} text={flow1Text} img={flow1Img} numOfStep={1} />
          <FlowStepBox key={1} title={flow2Title} text={flow2Text} img={flow2Img} numOfStep={2} />
          <FlowStepBox key={2} title={flow3Title} text={flow3Text} img={flow3Img} numOfStep={3} />
          <FlowStepBox key={3} title={flow4Title} text={flow4Text} img={flow4Img} numOfStep={4} />
        </FlexDiv>
      </BoxDiv>
    </ProgramDiv>
  );
};

export default FlowSection;
