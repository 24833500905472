import React, { Component, Fragment } from 'react';
import {
  BasicLayout,
  // ExcerptNews,
  ProgramContainer,
  Button,
  BottomSection,
  LinkHandle,
} from '../';
import styled from 'styled-components';

import { ImagePluTextBox } from '../';
// import hero from '../../assets/images/COS-WebsiteHomePageHeroVideoOptimized.mp4';
import { BottomSectionText } from '../../constants/BottomSectionText';
import { buttonText } from '../../constants/buttonText';
import colors from '../colors';
import { media } from '../../utils/Helper';
import { IP } from '../../utils/IPadress';
import LoadingBox from '../Common/LoadingBox';
import ExcerptNews from './ExcerptNews/ExcerptNews';
import BackgroundFilter from '../../assets/images/filter_video.png';
import HeroVideo from '../../assets/images/COS Website_Home_Page_Hero_Video.mp4';
import Heroimg from '../../assets/images/hero_cosLogo.png';

interface OwnProps {}
interface OwnState {
  data: {
    featured_media: number;
    acf: {
      metaDescription: string;
      herotext: string;
      ryugakutext: string;
      ryugakuimg: string;
      ryugakuimgalt: string;
    };
  };
}
const HeroBox = styled.div`
  position: relative;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  &.player-box {
    overflow: hidden;
  }
  &:after {
    background-image: url("${BackgroundFilter}");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 99.5%;
    content: ' ';
  }

  #video-div {
    width: 100%;
    ${media.phone`
    height: 80vh !important;
    width: auto !important;
    position: relative;
    left: 50%;
    // 通常は画像の半分だが、動画内のコンテンツに合わせて微調整
    margin-left: -450px;
    `}
  }
`;

const HeroText = styled.div`
  position: absolute;
  color: #fff;
  text-shadow: #000 1px 0 10px;
  font-size: 2.4rem;
  line-height: 1.5;
  top: calc(50% - 4.8rem);
  width: 100%;
  z-index: 1;
  & p {
    text-align: center;
    font-weight: bold;
  }
`;

class LandingPage extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      data: {
        featured_media: 0,
        acf: {
          metaDescription: '',
          herotext: '',
          ryugakutext: '',
          ryugakuimg: '',
          ryugakuimgalt: '',
        },
      },
    };
  }

  componentDidMount() {
    let pageId = '1313';
    let dataURL = `${IP}/wp-json/wp/v2/pages/${pageId}`;
    fetch(dataURL)
      .then(res => res.json())
      .then(res => {
        const { data } = this.state;
        const { featured_media, acf } = data;

        this.setState({
          data: {
            featured_media: res.featured_media || featured_media,
            acf: res.acf || acf,
          },
        });
      });
  }

  render() {
    let data = this.state.data.acf;
    const metaData = {
      title:
        '各分野のプロから専門留学に関するアドバイスが貰える留学エージェント',
      metaDescription: data.metaDescription,
      eyeCatchId: this.state.data.featured_media,
      ogLink: '/',
    };
    return (
      <BasicLayout metaData={metaData}>
        <HeroBox className="player-box">
          <video
            id="video-div"
            preload="auto"
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            poster=""
          >
            <source src={`${HeroVideo}`} type="video/mp4" />
            <picture>
              <img
                src={`${Heroimg}`}
                title="Your browser does not support the <video> tag"
                alt="Your browser does not support the <video> tag"
              />
            </picture>
          </video>
          <HeroText dangerouslySetInnerHTML={{ __html: data.herotext }} />
        </HeroBox>
        {data.ryugakuimg ? (
          <Fragment>
            <ImagePluTextBox
              imgURL={data.ryugakuimg}
              alt={data.ryugakuimgalt}
              isImgRightSide={true}
              classname={null}
            >
              <div dangerouslySetInnerHTML={{ __html: data.ryugakutext }} />
            </ImagePluTextBox>
            {/* コンテンツが出来るまで一旦非表示 */}
            <ExcerptNews limit={3} />
            <ProgramContainer />
          </Fragment>
        ) : (
          <Fragment>
            <LoadingBox />
          </Fragment>
        )}

        <BottomSection
          text={BottomSectionText.pattern1}
          backgroundColor={colors.lightGray}
        >
          <Button theme={{ main: '23.7rem' }}>
            <LinkHandle to={'/contact'}>{buttonText.freeConsulting}</LinkHandle>
          </Button>
        </BottomSection>
      </BasicLayout>
    );
  }
}

export default LandingPage;
