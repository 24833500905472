import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Media from 'react-media';

import FooterProgram from './FooterProgram';
import FooterProgramMobile from './FooterProgramMobile';
import color from '../../colors';
import { media } from '../../../utils/Helper';
import { getFooterMainMenu } from '../../../utils/MenuApi';

const FlexBox = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1200px;
  justify-content: ${({ theme }) => theme.content};
  & a {
    color: #fff;
  }
  & a:hover {
    color: ${color.primary};
  }
  &.nonFlex {
    ${media.tablet`display: block;
     padding-right:0; padding-bottom:0rem`}
  }
`;
const MenuBox = styled.div`
  width: 100%;
  .multipleRow {
    padding-right: 0;
    .menuItems {
      margin-bottom: 0rem;
    }
  }
`;

interface OwnProps {}
interface OwnState {
  menus: {
    items: [
      {
        title: string;
        url: string;
        children: [{ title: string; url: string }];
      },
      {
        title: string;
        url: string;
        children: [{ title: string; url: string }];
      },
      {
        title: string;
        url: string;
        children: [{ title: string; url: string }];
      },
      {
        title: string;
        url: string;
        children: [{ title: string; url: string }];
      },
      {
        title: string;
        url: string;
        children: [{ title: string; url: string }];
      }
    ];
  };
}

class FooterItem extends Component<OwnProps, OwnState> {
  constructor(ownProps: any, ownState: any) {
    super(ownProps, ownState);
    this.state = {
      menus: {
        items: [
          { title: '', url: '', children: [{ title: '', url: '' }] },
          { title: '', url: '', children: [{ title: '', url: '' }] },
          { title: '', url: '', children: [{ title: '', url: '' }] },
          { title: '', url: '', children: [{ title: '', url: '' }] },
          { title: '', url: '', children: [{ title: '', url: '' }] },
        ],
      },
    };
  }

  async getMenuData() {
    const menuData = await getFooterMainMenu();

    if (menuData && menuData.items) {
      this.setState({
        menus: menuData,
      });
    }
  }

  componentDidMount() {
    this.getMenuData();
  }

  render() {
    let menus = this.state.menus.items;
    const desktopDom = (
      <Fragment>
        <FooterProgram menu={menus[0]} class="" />
        <FooterProgram menu={menus[1]} class="" />
        <MenuBox>
          <FooterProgram menu={menus[2]} class="multipleRow" />
          <FooterProgram menu={menus[3]} class="multipleRow" />
        </MenuBox>
        <FooterProgram menu={menus[4]} class="" />
      </Fragment>
    );
    const tabletDom = (menus: any) => {
      let returnDom: JSX.Element[] = [];
      menus.forEach((menu: any, index: number) => {
        returnDom.push(<FooterProgramMobile menu={menu} key={index} />);
      });
      return returnDom;
    };

    return (
      <FlexBox className="reverse">
        <FlexBox className="nonFlex">
          <Media query="(max-width: 1024px)">
            {matches => (matches ? tabletDom(menus) : desktopDom)}
          </Media>
        </FlexBox>
      </FlexBox>
    );
  }
}
export default FooterItem;
